import { useMutation } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { ElMessage } from 'element-plus'

export default function useupdateAyersOrder (state: any) {
  const { mutate, onDone } = useMutation(gql.updateTodayAyersOrders)
  onDone(res => {
    const { updateTodayAyersOrders } = res.data
    state.updateFlag = true
    state.updateLoading = true
    state.confirmPopupFlag = false
    ElMessage({
      message: '改单已提交',
      type: 'success',
      duration: 1500,
      customClass: 'custom-success'
    })
    state.updateData = state.tradingData.map((item: any) => {
      item.info = updateTodayAyersOrders
      return item
    })
    state.updateLoading = false
    state.buyLoading = false
  })
  return {
    updateAyersOrder: mutate
  }
}
