import { COMMON } from '@/define'
export const tHeaderList = [
  {
    type: 'selection',
    prop: 'selectStatus',
    width: '50',
    // fixed: 'left',
    label: ''
  },
  // { label: '序列号', type: 'index', width: '80' },
  { label: '排序', type: 'sort', iconWidth: 16, iconHeight: 16, width: '100' },
  {
    label: '姓名/账户',
    prop: '',
    type: 'twoLines',
    width: '',
    propList: [{ prop: 'nameCn' }, { prop: 'fundAccount' }]
  },
  {
    label: '总资产',
    prop: 'assetDisplayNF',
    type: '',
    width: '',
    class: 'line-right'
  },
  {
    label: '市场购买力(HKD)',
    prop: 'enableBalanceDisplayN',
    type: 'specialTip',
    width: '180'
  },
  {
    label: '下单数量',
    prop: 'num',
    type: 'addSub',
    width: '200px',
    iconWidth: 34,
    iconHeight: 34,
    btnTypeSub: 'sub',
    btnTypeAdd: 'add',
    btnTypeInput: 'inputBlur',
    btnTypeC: 'btnTypeC'
  },
  {
    label: '持仓股票/数量',
    prop: 'enName',
    type: 'twoLines',
    width: '',
    class: 'right',
    align: 'right',
    propList: [{ prop: 'stockName' }, { prop: 'currentAmount' }]
  }
]

// 选项(港股)
export const listHK = [
  {
    label: '限价单 (增强) ',
    value: 'ELO',
    valueOf: 'ELO1'
  },
  {
    label: '市价单',
    value: 'MO',
    valueOf: 'MO1'
  },
  {
    label: '竞价限价单',
    value: 'ALO',
    valueOf: 'ALO1'
  },
  {
    label: '竞价市价单',
    value: 'AMO',
    valueOf: 'AMO1'
  }
]
// 选项(美股)
export const listUS = [
  {
    label: '限价单',
    value: 'LO',
    valueOf: 'LO2'
  },
  {
    label: '市价单',
    value: 'MO',
    valueOf: 'MO2'
  }
]
// 选项(A股)
export const listA = [
  {
    label: '限价单',
    value: 'LO',
    valueOf: 'LO3'
  }
]

export const S5LIST = [
  {
    priceKey: 'ask1',
    qtyKey: 'askQty1',
    label: '卖1',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  },
  {
    priceKey: 'ask2',
    qtyKey: 'askQty2',
    label: '卖2',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  },
  {
    priceKey: 'ask3',
    qtyKey: 'askQty3',
    label: '卖3',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  },
  {
    priceKey: 'ask4',
    qtyKey: 'askQty4',
    label: '卖4',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  },
  {
    priceKey: 'ask5',
    qtyKey: 'askQty5',
    label: '卖5',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  }
]
export const B5LIST = [
  {
    priceKey: 'bid1',
    qtyKey: 'bidQty1',
    label: '买1',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  },
  {
    priceKey: 'bid2',
    qtyKey: 'bidQty2',
    label: '买2',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  },
  {
    priceKey: 'bid3',
    qtyKey: 'bidQty3',
    label: '买3',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  },
  {
    priceKey: 'bid4',
    qtyKey: 'bidQty4',
    label: '买4',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  },
  {
    priceKey: 'bid5',
    qtyKey: 'bidQty5',
    label: '买5',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  }
]
