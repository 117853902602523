
import { computed, defineComponent } from 'vue'
export default defineComponent({
  props: {
    dialogVisible: { type: Boolean, default: false },
    title: { type: String, default: '' }
  },
  emits: ['update:dialogVisible', 'brokenStock'],
  setup (props, { emit }) {
    const dialogVisibleFlag = computed({
      get () {
        return props.dialogVisible
      },
      set (val) {
        emit('update:dialogVisible', val)
      }
    })
    const handleClose = () => {
      emit('update:dialogVisible', false)
    }
    const close = () => {
      emit('update:dialogVisible', false)
    }
    /*
     *@Description: 确定
     *@param: {*}
     *@return: {*}
     */
    const confirm = () => {
      emit('brokenStock')
    }
    return {
      handleClose,
      confirm,
      close,
      dialogVisibleFlag
    }
  }
})
