import { useLazyQuery, useMutation } from '@vue/apollo-composable'
import * as gql from '@/graphql'

export default function useUpdateOrder (state: any) {
  const { mutate, onDone } = useMutation(gql.lpoaUpdateOrderBatch)
  // 改单结果
  const { onResult, load } = useLazyQuery(gql.lpoaUpdateOrderTaskInfo)
  onDone(res => {
    if (res?.data?.lpoaUpdateOrderBatch.ok) {
      const taskid = res?.data?.lpoaUpdateOrderBatch.data.taskid
      setTimeout(() => {
        state.buyLoading = false
        state.confirmPopupFlag = false
        load(gql.lpoaUpdateOrderTaskInfo, {
          filter: { taskid }
        })
      }, 1500)
      onResult(res => {
        state.updateFlag = true
        state.updateLoading = true
        state.updateData = res?.data?.lpoaUpdateOrderTaskInfo?.data?.info
        state.updateLoading = false
      })
    }
  })
  return {
    updateOrderFunc: mutate
  }
}
