import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.dialogVisibleFlag,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialogVisibleFlag) = $event)),
    title: "提示",
    width: "500px",
    "before-close": _ctx.handleClose,
    "destroy-on-close": "",
    center: "",
    "show-close": false
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_el_button, {
        class: "content-close",
        onClick: _ctx.close
      }, {
        default: _withCtx(() => [
          _createTextVNode("取消")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_el_button, {
        class: "content-confirm",
        type: "primary",
        onClick: _ctx.confirm
      }, {
        default: _withCtx(() => [
          _createTextVNode("确定")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, " 因您下单的股数按" + _toDisplayString(_ctx.title) + "拆分后会存在碎股，请问是否需要将碎股去掉，取整数？ ", 1)
    ]),
    _: 1
  }, 8, ["modelValue", "before-close"]))
}