/**
 * 处理数据(平均分配)
 */
const computedData = (averageL: any, arr: any, buyHand: any, num: any) => {
  const muchEach = averageL / arr.length
  // 不能被整除 且每个基数小于0
  if (averageL % arr.length !== 0 && muchEach < 1) {
    for (let i = 0; i < arr.length; i++) {
      if (Number(num) - Number(buyHand) >= 0) {
        arr[i].num = Number(buyHand)
        num = num - Number(buyHand)
      } else if (num > 0) {
        arr[i].num = num
        num = 0
      } else {
        arr[i].num = 0
      }
    }
  } else {
    let numL = 0
    // 计算平均基数每个应该是多少
    for (let i = 0; i < arr.length; i++) {
      numL += ~~muchEach * Number(buyHand)
      arr[i].num = ~~muchEach * Number(buyHand)
    }
    let numL1 = num - numL
    // 把剩余的数量按照每一手的数量进行相加
    for (let i = 0; i < arr.length; i++) {
      if (Number(numL1) - Number(buyHand) >= 0) {
        arr[i].num = arr[i].num += Number(buyHand)
        numL1 = numL1 - Number(buyHand)
      } else if (numL1 > 0) {
        arr[i].num = arr[i].num += numL1
        numL1 = 0
      } else {
        arr[i].num = arr[i].num += numL1
      }
    }
  }
  return arr
}

/**
 * 平均分配的计算方式
 */
export const forNumber = (buyHand: any, num1: any, arr1: any) => {
  let num = num1
  let arr = arr1
  if (num === '' || num === 0 || num === 'undefined') {
    for (let i = 0; i < arr.length; i++) {
      arr[i].num = 0
    }
    return
  }
  const averageL = ~~(Number(num) / Number(buyHand))
  // 每一首都能被整除
  if (averageL % buyHand === 0) {
    arr = computedData(averageL, arr, buyHand, num)
    // 数量小于一手的数
  } else if (Number(num) <= Number(buyHand)) {
    for (let i = 0; i < arr.length; i++) {
      if (Number(num) >= 0) {
        arr[i].num = Number(num)
        num = 0
      } else {
        arr[i].num = num
      }
    }
    // 数量大于一手的数
  } else {
    arr = computedData(averageL, arr, buyHand, num)
  }
  console.log(arr)
  return arr
}

/**
 * 资产比例的计算方式
 */
export const forZcNumber = (
  buyHand1: any,
  num1: any,
  arr1: any,
  exchangeType: any
) => {
  const num = Number(num1)
  const arr = arr1
  const buyHand = buyHand1
  const arrN1 = []
  const a = []
  console.log(arr, 'arr')
  for (let i = 0; i < arr.length; i++) {
    a.push(arr[i].accounted * num)
    // 处理精度问题
    arr[i].num = ~~(arr[i].accounted * num)
  }
  console.log(a)
  if (arr.length > 0 && arr.length > 1) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].num < buyHand) {
        arrN1.push(0)
        arr[i].num = 0
      } else if (arr[i].num % buyHand !== 0) {
        arrN1.push(arr[i].num - (arr[i].num % buyHand))
        arr[i].num = arr[i].num - (arr[i].num % buyHand)
      } else if (arr[i].num % buyHand === 0) {
        if (exchangeType === 'US') {
          arrN1.push(arr[i].num)
        } else {
          arrN1.push(arr[i].num)
        }
      }
    }
    console.log(arrN1)
    // 计算取出来的数据之和
    const total1 =
      arrN1.length > 0 ? arrN1?.reduce((a: any, b: any) => a + b) : 0
    let total = num - total1
    if (total <= buyHand) {
      arr[0].num += total
    } else {
      for (let i = 0; i < arr.length; i++) {
        if (total > buyHand) {
          arr[i].num += buyHand
          total = total - buyHand
        } else if (total <= buyHand) {
          arr[i].num += total
          total = 0
        }
      }
    }
    return arr
  } else if (arr.length > 0 && arr.length === 1) {
    arr[0].num = num
    return arr
  }
}
