
import { defineComponent, computed, watch, ref } from 'vue'
import { numFormat } from '@/utils/num_format'
import { immTradeRemindInfo } from '@/apis/index'
import { ENTRUSTPROPMAP } from '@/constant'

export default defineComponent({
  props: {
    title: { type: String, default: '' },
    dialogVisible: { type: Boolean, default: false },
    tradingData: { type: Array, default: () => [] } as any,
    buyLoading: { type: Boolean, default: false },
    price: { type: String, default: '' },
    totalAmount: { type: String, default: '' },
    tradingObj: { type: Object, default: {} }
  },
  emits: ['update:dialogVisible', 'buyNow'],
  setup(props, { emit }) {
    console.log(props)

    const dialogVisibleFlag = computed({
      get() {
        return props.dialogVisible
      },
      set(val) {
        emit('update:dialogVisible', val)
      }
    })

    const showPrice = computed(() => {
      if (props.tradingObj.entrustProp.includes('市价')) {
        return '市价'
      } else {
        return props.tradingObj.price
      }
    })

    const tableData = ref<any>([])
    const loading = ref(true)

    const close = () => {
      emit('update:dialogVisible', false)
    }

    const confirm = () => {
      emit('buyNow')
    }

    const computedTradingData = async (): Promise<any> => {
      try {
        loading.value = true
        const { entrustBs, entrustProp } = props.tradingObj
        const entrustIndex = Object.values(ENTRUSTPROPMAP).findIndex(
          (item) => item === entrustProp
        )
        const orderType = Object.keys(ENTRUSTPROPMAP)[entrustIndex]
        const bsFlag = entrustBs === '买入' ? 'B' : 'S'

        const clientsTradeInfo = [...props.tradingData].map(v => ({
          clientId: v.fundAccount,
          orderType,
          bsFlag,
          businessAmount: +((v.num * Number(props.price))?.toFixed(4) || 0)
        }))
        console.log(clientsTradeInfo, 'clientsTradeInfo')
        const res = await immTradeRemindInfo({
          param: {
            clientsTradeInfo
          }
        })
        const { ok, data } = res.data.immTradeRemindInfo
        if (ok) {
          tableData.value = [...props.tradingData].map(v => {
            const item = data.find((item: any) => item.clientId === v.fundAccount)
            return {
              ...v,
              ...item
            }
          })
        } else {
          tableData.value = props.tradingData
        }
        console.log(tableData.value, 'tableData.value')
      } finally {
        loading.value = false
      }
    }

    watch(
      () => props.dialogVisible,
      (val) => {
        console.log(val, 'val')
        if (val) {
          computedTradingData()
        } else {
          tableData.value = props.tradingData
        }
      },
      {
        deep: true
      }
    )

    return {
      close,
      confirm,
      numFormat,
      dialogVisibleFlag,
      showPrice,
      tableData,
      loading
    }
  }
})
