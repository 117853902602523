import { useQuery } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { ref } from 'vue'

export default function useTimeZone () {
  const timeIsDst = ref(false)
  const { refetch, onResult } = useQuery(gql.serverTime, {
    params: {
      timeZone: 'Asia/Shanghai'
    }
  })
  onResult(res => {
    const { serverTime } = res.data
    timeIsDst.value = serverTime.timeIsDst
  })
  return {
    timeIsDst,
    refetchTime: refetch
  }
}
